body{
	overscroll-behavior: none;
	margin: 0;
	background-color: #2061ae;
}

#root {
	background-color: #fff;
}

.content {
	margin: 0;
	padding: 0;
	padding-top: 64px;
	padding-bottom: 70px;
	min-height: calc(100vh - 64px);
}

.paddedContent {
	position: relative;
	padding-left: 20px;
	padding-right: 20px;
}

hr {
	margin: 0;
	border: 0.5px solid rgba(0, 0, 0, 0.2);
}

hr.light {
	width: 100%;
	margin: 0;
	border: 0.5px solid rgba(255, 255, 255, 0.5);
}

.smallTitle {
	font-size: 26px;
	font-weight: 600;
	padding: 0;
	margin: 0;
}

.smallTitle2 {
	font-style: normal;
	font-weight: 900;
	font-size: 15px;
	line-height: 22px;
	margin: 0;
	margin-bottom: 10px;
}

.hazard_list_title {
	font-weight: 900;
	font-size: 15px;
	line-height: 22px;
	padding: 0;
	margin: 0;
}

.hazard_list_text {
	font-size: 15px;
	line-height: 22px;
	padding: 0;
	margin: 0;
	margin-top: 10px;
}

.text {
	font-size: 15px;
	padding: 0;
	margin: 0;
}

.text2 {
	font-size: 15px;
	padding: 0;
	margin: 0;
	font-weight: bold;
}

button {
	transition: all 0.2s;
	width: 100%;
	min-height: 44px;
	border: none;
	background-color: #2061ae;
	border-radius: 4px;
	color: white;
	font-size: 17px;
	font-weight: 600;
	margin-top: 10px;
}

button:hover {
	background-color: #236cc3;
}

button:active {
	background-color: #1a58a1;
}

button:disabled {
	background-color: #cccdcf;
}

button#outline {
	background-color: transparent;
	border: 1px solid rgba(0, 0, 0, 0.3);
	color: black;
}

button#outline:hover {
	background-color: rgba(0, 0, 0, 0.08);
	border: 1px solid rgba(0, 0, 0, 0.08);
}

button#outline:active {
	background-color: rgba(0, 0, 0, 0.2);
	border: 1px solid rgba(0, 0, 0, 0.08);
}

button#outline:disabled {
	background-color: #cccdcf;
}

button#outline-card {
	width: 100px;
	min-height: 38px;
	padding: 0;
	background-color: transparent;
	border: 2px solid rgba(255, 255, 255, 0.8);
	color: white;
}

button#outline-card:hover {
	background-color: rgba(255, 255, 255, 0.08);
	border: 2px solid rgba(255, 255, 255, 0.8);
}

button#outline-card:active {
	background-color: rgba(255, 255, 255, 0.8);
	border: 2px solid rgba(255, 255, 255, 0.8);
}

button#outline-card:disabled {
	background-color: #cccdcf;
}

.loadingView {
	width: 60px;
	height: 60px;

	position: relative;
	margin: 100px auto;
}

input[type="text"] {
	width: 40%;
	border: none;
	color: #2061ae;
	font-size: 15px;
}

.tableViewCell textarea{
	width: 40%;
	border: none;
	color: #2061ae;
	font-size: 15px;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.tableViewCell#textarea textarea{
	width: 100%;
}

input[type="password"] {
	width: 40%;
	border: none;
	color: #2061ae;
	font-size: 15px;
}

select {
	width: 40%;
	border: none;
	color: #2061ae;
	font-size: 15px;
	margin-right: 6px;
	-webkit-appearance:none;
}

textarea {
	width: 100%;
	border: none;
	color: #2061ae;
	font-size: 15px;
	height: 200px;
	margin-top: 20px;
	margin-bottom: 20px;
}

img.fullWidth {
	width: 100%;
}

.launchLogo {
	width: 100%;
	height: 250px;
	background-image: url("./assets/LogoBox.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	margin-top: 44px;
}

.launchGradient {
	width: 100%;
	height: calc(100vh - 250px - 44px);
	background: linear-gradient(162.07deg, #2061ae 24.48%, #cedbeb 111.06%);
	position: absolute;
}

.launchTitle {
	text-align: center;
	margin-top: 100px;
	font-weight: bold;
	font-size: 15px;
	color: white;
}

table {
	margin-top: 20px;
}

th {
	background: #e0e8ef;
	padding: 7px 10px;
	text-align: left;
	color: #61494a;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 18px;
}

td {
	font-size: 13px;
	line-height: 18px;
	padding: 7px 10px;
	color: #000;
}

table tr:nth-child(even) td {
	background: white;
}
table tr:nth-child(odd) td {
	background: #f5f5f3;
}

table tbody:nth-child(even) td {
	background: white;
}
table tbody:nth-child(odd) td {
	background: #f5f5f3;
}

.visible_intro{
	top: 0;
	background-color: rgba(0,0,0,0.5);
	position: fixed;
	z-index: 100;
}

.visible_intro button {
	position: fixed;
	z-index: 100;
	right: 100px;
	color: #fff;
	width: 100px
}

.visible_intro p{
	position: fixed;
	width: 100%;
	bottom: 100px;
	z-index: 100;
	text-align: center;
	margin-top: 20px;
	color: #fff;
	font-size: 20px;
}

.visible_intro img{
	width: 200px;
	position: fixed;
	bottom: 100px;
	right: 100px;
}
