.tabBar {
	width: 100%;
	height: 60px;
	background-color: #f9f9f9;
	position: fixed;
	bottom: 0;
	padding-bottom: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	border-top: 0.5px solid rgba(0, 0, 0, 0.3);
}

.tabBarItem {
	min-width: 100px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 12px;
	align-items: center; /* center items horizontally */
	color: gray;
}

.tabBarItem#selected {
	color: #2061ae;
}

.tabBarItem-image {
	width: 28px;
	height: 28px;
	margin-bottom: 5px; /* gap for flex is not supported widely enough yet */
	margin-top: 5px; /* gap for flex is not supported widely enough yet */
	background-image: url("../assets/chevron_right.svg");
	background-size: 28px 28px;
	background-repeat: no-repeat;
	filter: brightness(105%) invert(49%);
}

.tabBarItem-image#info {
	background-image: url("../assets/Tab_Info.svg");
}

.tabBarItem-image#infoSelected {
	background-image: url("../assets/Tab_Info.svg");
	filter: invert(30%) sepia(99%) saturate(729%) hue-rotate(180deg)
		brightness(90%) contrast(92%);
}

.tabBarItem-image#matrix {
	background-image: url("../assets/Tab_Quantity_check.svg");
}

.tabBarItem-image#matrixSelected {
	background-image: url("../assets/Tab_Quantity_check.svg");
	filter: invert(30%) sepia(99%) saturate(729%) hue-rotate(180deg)
		brightness(90%) contrast(92%);
}

.tabBarItem-image#settings {
	background-image: url("../assets/Tab_Settings.svg");
}

.tabBarItem-image#settingsSelected {
	background-image: url("../assets/Tab_Settings.svg");
	filter: invert(30%) sepia(99%) saturate(729%) hue-rotate(180deg)
		brightness(90%) contrast(92%);
}

.matrixGrid-wrapper .navBar{
	z-index: 0;
}
