.navBar {
  width: 100%;
  height: 44px;
  background-color: #2061ae;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; /* center items horizontally */
  z-index: 2;
}

.navBarItem {
  min-width: 20%;
  font-size: 17px;
  align-items: center; /* center items horizontally */
  color: #9bb8d7;
  margin: 0;
  padding: 0;
  margin-left: 20px;
  margin-right: 20px;
  cursor: default;
  user-select: none;
  flex-shrink: 0;
  font-weight: 600;
}

.navBarItem:active {
  color: white;
}

.navBarItem#left {
  text-align: left;
}

.navBarItem#right {
  text-align: right;
}

.navBarItem#disabled {
  text-align: right;
  opacity: 0.2;
}

.navBarItem#back {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center; /* center items horizontally */
}

.navBarItem-chevron {
  width: 10px;
  height: 34px;
  background-image: url("../assets/back_button.svg");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  margin-right: 6px;
}

.navBarTitle {
  font-size: 17px;
  font-weight: bold;
  color: white;
  cursor: default;
  user-select: none;
  text-align: center;
  flex-shrink: 0;
}
