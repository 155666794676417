.css-1lil0mp{
	opacity: 1 !important;
}

.stack-topRow {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

.stack-searchField {
	width: 100%;
	height: 50px;
	background: rgba(255, 255, 255, 0.5);
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	margin-left: 20px;
}

.favoritesButton {
	min-width: 50px;
	height: 50px;
	border-radius: 25px;
	background: rgba(255, 255, 255, 0.5);
	background-image: url("../assets/favorite.svg");
	background-size: 28px 28px;
	background-position: center;
	background-repeat: no-repeat;
}

.cell-deleteButton {
	height: 36px;
	background: color(srgb 0.923 0.923 0.923);
	line-height: 36px;
	font-size: 12px;
	font-weight: bold;
	border-radius: 3px;
	padding-left: 10px;
	padding-right: 10px;
}

p.stack-placeholder {
	padding-left: 60px;
	padding-right: 60px;
	color: #a8a9ab;
	font-size: 18px;
	font-weight: 600;
	line-height: 22px;
	text-align: center;
	margin-top: 150px;
}

input.searchfield {
	height: 50px;
	background: transparent;
	color: #444;
	font-size: 15px;
	line-height: 22px;
	text-align: left;
	width: 80%;
}

.stack-hint {
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 14px;
	/* or 140% */

	/* Colors/Grey/Grey 1 (Supporting Color) */
	color: #a8a9ab;
}

.card {
	position: relative;
	max-width: 95%;
	min-width: 30%;
	width: 100vw;
	background: #8f0032;
	/*display: flex;*/
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	transform-origin: 50% 50%;
	border-radius: 4px;
	overflow: hidden;
	min-height: 340px;
	box-shadow: 0 3px 10px color(srgb 0 0 0 / 0.122);
}

.card-leftSide {
	width: 100%;
	height: 100%;
	display: block;
	user-select: none;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	padding-left: 20px;
	padding-top: 16px;

	padding-right: 5px;
	box-sizing: border-box;
	color: white;
}

.card-leftSideDark {
	padding-left: 0px;
	color: #000;
	margin-bottom: 20px;
}

.card-inner-leftSide {
	display: flex;
}

.card-inner-leftSide .star-position {
	position: absolute;
	right: 20px;
}

.card-hr {
	width: 100%;
	border-top: 1.5px solid rgba(255, 255, 255, 0.5);
	margin-top: 8px;
	margin-bottom: 8px;
}

p.cardTitle {
	font-size: 22px;
	padding: 0;
	margin: 0;
	font-weight: bold;
}

p.cardTitle span {
	font-size: 18px;
	color: rgba(255, 255, 255, 0.7);
	padding-left: 10px;
	display: inline-block;
	font-weight: normal;
}

.card-leftSideDark p.cardTitle {
	margin-left: 10px;
}

.card-leftSideDark p.cardTitle span {
	font-size: 18px;
	color: rgba(0,0,0);
	padding-left: 10px;
	display: inline-block;
	font-weight: normal;
}

p.cardSmallText {
	font-size: 10px;
	padding: 0;
	margin: 0;
}

p.cardSmallBoldText {
	font-size: 12px;
	padding: 0;
	margin: 0;
}

.card-inner-right-rightSide p.cardSmallBoldText {
	font-size: 10px;
	padding: 0;
	margin: 0;
}

div.cardSmallBoldTexSubTitle {
	margin-top: 5px;
	font-size: 12px;
	margin-left: 30px;
}

.card-leftSideDark div.cardSmallBoldTexSubTitle {
	margin-left: 40px;
}

p.cardSmallBoldTextRight {
	font-size: 12px;
	padding: 0;
	margin: 0;
	text-align: right;
}

p.cardSmallBoldTextRight .checked_icon {
	width: 12px;
	height: auto;
}

.pictogram {
	width: 25px;
	height: auto;
	margin-top: 5px;
	margin-right: 5px;
}

.card-rightSide {
	width: 100%;
	height: 100%;
	display: block;
	user-select: none;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 16px;
	padding-bottom: 20px;
	box-sizing: border-box;
	color: white;
}

.card-inner-rightSide {
	display: flex;
}

.card-inner-rightSide .card-inner-left-rightSide {
	width: 100%;
}

.card-inner-right-rightSide {
	margin-top: 8px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
}

